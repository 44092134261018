<template>
	<div class="eb-course-list-wrapper py-3 px-1">
		<ul class="eb-course-list">
			<UserDashboardProductsListItem v-for="product in userProducts" :key="product.id"			
			:data="product"			
			:purchased="true" ></UserDashboardProductsListItem>
		</ul>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { ProductVariantStatus } from "../../constants/productVariant";
import  UserDashboardProductsListItem  from "./UserDashboardProductsListItem.vue";
export default {
	name: "UserDashboardProducts",
	props: {  },
	data(){
		return {
			//productVariantProgress:{}
		};
	},
	components: { UserDashboardProductsListItem },
	methods:{
		...mapActions("user", ["fetchTrainersList"]),		
		...mapActions("productVariant", ["getProductVariantProgress"])
		
	},
	computed: {
		...mapState("user", ["user","trainers"]),		
		userProducts() {
			var myProducts =
				this.user && this.user.productVariants
					? this.user.productVariants.filter(
							(productVariant) =>
								productVariant.status != ProductVariantStatus.DRAFT
								&& productVariant.product && productVariant.product.title
								
							// &&
							// ? productVariant.endDate
							// 	? new Date() >= productVariant.endDate
							// 	: true
							// : false
							// productVariant.subscriptionStatus &&
							// productVariant.subscriptionStatus.toLowerCase() != "unenrolled"
					  )
					: [];
			return myProducts
		},	
	},
	created:function(){
		this.fetchTrainersList().then(() => {
			console.log("trainers fetched");
		});
		this.getProductVariantProgress(null).then((data)=>{
		});
	},
	watch:{
		user:function(){
			console.log(this.user);
		}
	}
};
</script>

<style scoped>
</style>
