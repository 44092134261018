<style>
.truncate{
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: auto;
}
</style>
<template>
	
	<div
		:class="[
			'list-item',
			{
				processing:
					contentProgress &&
					contentProgress > 0 &&
					contentProgress < 100 &&
					contentData.id == contentLoadingId,
			},
		]"
	>
		<div class="m-2" v-if="isContentSectionSelectable">
			<input
				type="checkbox"
				name="select_all"
				id="select_all"
				@change="manageContentState($event)"
			/>
		</div>
		
		<img src="@/assets/images/drag-icon.svg" class="drag-icon content-handle handle me-3" />
		<div class="content-icon-border">
			<img
				v-if="contentData.type == ContentType.LIVE_CLASS"
				class="page-view-icon"
				src="@/assets/images/zoom-icon.png"
			/>
			<img
				v-if="contentData.type == ContentType.STUDY_MATERIAL"
				class="page-view-icon"
				src="@/assets/images/study-material.png"
			/>
			<img
				v-if="contentData.type == ContentType.DIRECTORY"
				class="page-view-icon"
				src="@/assets/images/folder-icon.png"
			/>
			<img
				v-if="contentData.type == ContentType.RECORDED_VIDEOS && contentData.externalService.serviceProvider.toLowerCase() == 'vss'"
				class="page-view-icon"
				src="@/assets/images/recorded-lecture.png"
			/>
			<img
				v-if="contentData.type == ContentType.RECORDED_VIDEOS && contentData.externalService.serviceProvider.toLowerCase() == 'youtube'"
				class="page-view-icon"
				src="@/assets/images/youtube-icon.png"
			/>
			<img
				v-if="contentData.type == ContentType.EXAM || contentData.type == ContentType.QUIZ"
				class="page-view-icon"
				src="@/assets/images/exam-test.png"
			/>
			<img
				v-if="contentData.type == ContentType.FLASHCARDS"
				class="page-view-icon"
				src="@/assets/images/flash-card.png"
			/>
			<img
				v-if="contentData.type == ContentType.ASSIGNMENT"
				class="page-view-icon"
				src="@/assets/images/assignment.png"
			/>
			<img
				v-if="contentData.type == ContentType.FREETEXT"
				class="page-view-icon"
				src="@/assets/images/freeText.png"
			/>
		</div>
		<div class="d-flex flex-column">
			<div class="d-flex flex-row align-items-center gap-2">
				<span class="text-500">
					<span class="text-bold text-muted">{{ contentData.id }}: </span>
					{{ contentData.title }} 
				</span>
				<div v-if="contentData.isContentAvailableForDemo" class="demo-tag">
					Demo
				</div>
			</div>
			<b-progress
				v-if="
					contentProgress &&
					contentProgress < 100 &&
					contentData.id == contentLoadingId
				"
				:value="contentProgress"
				:max="100"
				show-progress
				animated
				class="m-0"
			></b-progress>
			<span
				v-else-if="contentData.type == ContentType.ASSIGNMENT"
				class="text-muted">
				<span v-if="contentExtraProperties.Deadline"><b>Deadline : </b> {{new Date(contentExtraProperties.Deadline).toDateString()}} {{new Date(contentExtraProperties.Deadline).toLocaleTimeString()}}</span>
				
			</span>
			<span
				class="text-muted"
				v-else-if="
					!contentExtraProperties ||
					(contentExtraProperties && contentData.type != ContentType.LIVE_CLASS && contentData.type != ContentType.EXAM)
				"
			>
				{{
					!contentData.availableTo && contentData.availableFrom
						? "Starts from "
						: ""
				}}
				{{
					contentData.availableTo && !contentData.availableFrom
						? "Ends at "
						: ""
				}}
				{{
					contentData.availableFrom ? dateParser(contentData.availableFrom) : ""
				}}
				{{ contentData.availableFrom && contentData.availableTo && "to" }}
				{{ contentData.availableTo ? dateParser(contentData.availableTo) : "" }}
			</span>
			<div
				v-if="
					contentData.type == ContentType.LIVE_CLASS && contentExtraProperties
				"
			>
				<div class="text-muted">
					<strong>Host: </strong>{{ contentExtraProperties.Host }}
				</div>
				<div class="text-muted" v-if="contentExtraProperties">
					<strong>Start Time:</strong>
					{{ timeParser(contentData.availableFrom) }},
					{{ dateParser(contentData.availableFrom) }} |
					<strong> Duration:</strong>
					{{ calculatedDuration() }} Minutes
				</div>
				<div class="text-muted" v-if="contentExtraProperties">
					<strong>Type:</strong>
					{{
						contentExtraProperties.RegistrantAllow
							? "Registrants Only"
							: "Open For All"
					}}
					|
					{{
						contentExtraProperties.OpenInSDK
							? "Open on Portal"
							: "Open in Zoom Software"
					}}
				</div>
			</div>
			<div
				v-if="
					(contentData.type == ContentType.RECORDED_VIDEOS ||
					contentData.type == ContentType.STUDY_MATERIAL) && contentData.externalService.serviceProvider.toLowerCase()=='vss' &&
					contentExtraProperties
				"
			>
				<div class="text-muted">
					<strong>Status: </strong>{{ contentExtraProperties.Status }}
				</div>
				<div class="text-muted">
					<span v-if="contentExtraProperties.SizeInMB">
						<strong>Size: </strong>{{ contentExtraProperties.SizeInMB }} MB <span  v-if="contentData.type == ContentType.RECORDED_VIDEOS">|</span>
					</span>
					<span v-if="contentData.type == ContentType.RECORDED_VIDEOS && contentExtraProperties.Duration">
						<strong> Duration: </strong
						>{{
							contentExtraProperties.Duration
								? (contentExtraProperties.Duration / 60).toFixed(1)
								: ""
						}}
						Minutes
					</span>
				</div>
			</div>

			<div
				v-if="
					contentData.type == ContentType.EXAM &&
					contentData.externalService.serviceProvider.toLowerCase() ==
											ServiceCodes.EXAM.V3ExamSystem.toLowerCase() &&
					contentExtraProperties
				"
			>
				<div class="text-muted" v-if="typeof(contentExtraProperties)=='string' && JSON.parse(contentExtraProperties).Layout">
					<strong>Layout: </strong>{{ JSON.parse(contentExtraProperties).Layout=="Start"?"F5 to F9 + Professional Level ACCA Exam Format":"F1 to F4 (ACCA)" }}
				</div>
				<div class="text-muted" v-else-if="contentExtraProperties.Layout">
					<strong>Layout: </strong>{{ contentExtraProperties.Layout=="Start"?"F5 to F9 + Professional Level ACCA Exam Format":"F1 to F4 (ACCA)" }}
				</div>
				<div class="text-muted">
				{{
					!contentData.availableTo && contentData.availableFrom
						? "Starts from "
						: ""
				}}
				{{
					contentData.availableTo && !contentData.availableFrom
						? "Ends at "
						: ""
				}}
				{{
					contentData.availableFrom ? dateParser(contentData.availableFrom) : ""
				}}
				{{ contentData.availableFrom && contentData.availableTo && "to" }}
				{{ contentData.availableTo ? dateParser(contentData.availableTo) : "" }} 
				{{ contentData.availableFrom || contentData.availableTo ? "| ":"" }}
				<span  v-if="typeof(contentExtraProperties)=='string'"><b>Duration : </b>{{examTimeParser(JSON.parse(contentExtraProperties).TotalTime)}}</span>
				<span  v-else><b>Duration : </b>{{examTimeParser(contentExtraProperties.TotalTime)}}</span></div>
				
			</div>
		</div>

		<div class="right-menu">
			<div class="content-cta demo gap-2 d-flex">
				<button
					:disabled="loading"
					@click="handleClick"
					type="button"
					class="border-btn"
					v-if="showActionButton"
				>
					<b-spinner small v-if="loading"></b-spinner>
					{{ buttonText }}
				</button>
				<button
					v-if="
						contentData.type == ContentType.EXAM &&
						contentData.externalService.serviceProvider !=
							ServiceCodes.EXAM.V3ExamSystem
					"
					:disabled="loading"
					@click="viewExamResults"
					type="button"
					class="border-btn preview"
				>
					<b-spinner small v-if="loading"></b-spinner>
					View Results
				</button>		
							
				<b-dropdown text="Actions" variant="outline-dark" class="dropdown-preview" v-if="
										contentData.type == ContentType.EXAM &&
										contentData.externalService.serviceProvider.toLowerCase() ==
											ServiceCodes.EXAM.V3ExamSystem.toLowerCase()
									" :disabled="loading">
					<b-dropdown-item @click="toggleExamQuestionsModal(true,'create')" ><b-spinner small v-if="loading"></b-spinner>
						Add Question
					</b-dropdown-item>
					<b-dropdown-item  @click="toggleExamQuestionsModal(true,'list')"
					><b-spinner small v-if="loading"></b-spinner>
					View Questions</b-dropdown-item>				
					<b-dropdown-item @click="toggleExamActionModal(true,LegacyExam.GET_EXAM_ACTION.VIEW_RESULTS)">
						<b-spinner small v-if="loading"></b-spinner>
						View Test Wise Report
					</b-dropdown-item>							
					<b-dropdown-item @click="toggleExamActionModal(true,LegacyExam.GET_EXAM_ACTION.REVIEW_QUESTIONS)">
						<b-spinner small v-if="loading"></b-spinner>
						Review Question Answers
					</b-dropdown-item>					
				</b-dropdown>

				<b-dropdown text="Actions" variant="outline-dark" class="dropdown-preview" v-if="
										contentData.type == ContentType.QUIZ" :disabled="loading">
					<b-dropdown-item @click="toggleQuizQuestionsModal(true,'create')" ><b-spinner small v-if="loading"></b-spinner>
						Add Question
					</b-dropdown-item>
					<b-dropdown-item @click="toggleViewQuizQuestionsModal(true)" ><b-spinner small v-if="loading"></b-spinner>
						View Questions
					</b-dropdown-item>
					<!-- <b-dropdown-item  @click="toggleExamQuestionsModal(true,'list')"
					><b-spinner small v-if="loading"></b-spinner>
					View Questions</b-dropdown-item> -->
				</b-dropdown>

				<button
					v-if="contentData.type == ContentType.LIVE_CLASS || (contentData.type == ContentType.RECORDED_VIDEOS && contentData.externalService.serviceProvider.toLowerCase() == 'vss')"
					:disabled="loading"
					@click="toggleLiveClassAttendanceModal(true)"
					type="button"
					class="border-btn preview"
				>
					<b-spinner small v-if="loading"></b-spinner>
					Attendance
				</button>
			</div>
			<a role="button" class="right-menu-icon" @click="copyContent">
				<i class="fal fa-copy"></i>
			</a>
			<a v-if="contentData.type == ContentType.LIVE_CLASS" role="button" class="right-menu-icon" @click="copyJoinLink" title="Copy Link">
				<i class="fal fa-link"></i>
			</a>
			<a role="button" class="right-menu-icon" @click="showModal">
				<img src="@/assets/images/edit-icon.svg"  />
			</a>
			
			<a
				@click="showDeleteConfirmPopup"
				role="button"
				class="right-menu-icon delete"
			>
				<img src="@/assets/images/delete-icon.svg" />
			</a>
		</div>
		<StudyMaterial
			v-if="contentData.type == ContentType.STUDY_MATERIAL"
			:showModal="showStudyMaterialModal"
			@updateModalState="toggleStudyMaterialModal"
			:contentId="contentData.id"
		/>
		
		<ManageAssignmentModal
			:isEditMode="false"
			:visible="this.showManageAssignmentsModal"
			:contentData="contentData"
			@updateModalState="toggleManageAssignmentsModal"
		/>
		<LiveClassAttendanceModal
				v-if="contentData.type == ContentType.LIVE_CLASS"
				@updateModalState="toggleLiveClassAttendanceModal"
				:showModal="showLiveClassAttendanceModal"
				:productVariantId="productVariantId"
				:contentData="contentData"

			>
			</LiveClassAttendanceModal>
			<ContentAttendanceModal
				v-if="contentData.type == ContentType.RECORDED_VIDEOS"
				@updateModalState="toggleLiveClassAttendanceModal"
				:showModal="showLiveClassAttendanceModal"
				:productVariantId="productVariantId"
				:contentData="contentData"

			>
			</ContentAttendanceModal>
			<ExamQuestionsModal
			v-if="contentData.type == ContentType.EXAM"
				@updateModalState="toggleExamQuestionsModal"
				:visible="showExamQuestionsModal"
				:item="contentData"
				:mode="examQuestionsModal_Mode"
			>
			</ExamQuestionsModal>
			<QuizQuestionsModal
				v-if="contentData.type == ContentType.QUIZ"
				@updateModalState="toggleQuizQuestionsModal"
				:visible="showQuizQuestionsModal"
				:item="contentData"
				:mode="quizQuestionsModal_Mode"
			/>
			<ViewQuizQuestionsModal
				v-if="contentData.type == ContentType.QUIZ && showViewQuizQuestionsModal"
				@updateModalState="toggleViewQuizQuestionsModal"
				:showModal="showViewQuizQuestionsModal"
				:contentData="contentData"
				:mode="quizQuestionsModal_Mode"
			/>
			<ExamActionModal				
				v-if="contentData.type == ContentType.EXAM"
				@updateModalState="toggleExamActionModal"
				:visible="showExamActionModal"
				:item="contentData"
				:action="examActionModal_Action"
			>
			</ExamActionModal>
			<CreateZoomMeetingModal
				v-if="contentData.type == ContentType.LIVE_CLASS"
				mode="edit"
				:visible="showEditModal"
				:parentContentId="parentDirectoryID"
				:meetingData="contentExtraProperties"
				:contentData="contentData"
				@updateModalState="toggleEditModal"
			>
			</CreateZoomMeetingModal>
			<CreateEditLegacyExamModal 
			v-else-if="contentData.type == ContentType.EXAM &&
				contentData.externalService.serviceProvider.toLowerCase() ==
										ServiceCodes.EXAM.V3ExamSystem.toLowerCase()" 
				:showModal="showEditModal" 			
				mode="edit"
				:parentContentId="parentDirectoryID"
				@updateModalState="toggleEditModal" 
				:productVariantId="productVariantId"
				:contentData="contentData"
				:isEditMode="true"
			 />
			<UploadRecordedLectureModal
				v-else-if="contentData.type == ContentType.RECORDED_VIDEOS && contentData.externalService.serviceProvider.toLowerCase() == 'vss'"
				:showModal="showEditModal"
				:parentContentId="parentDirectoryID"
				@updateModalState="toggleEditModal"
				:productVariantId="productVariantId"
				:contentData="contentData"
				:service="contentData.externalService"
			    :contentType="contentData.type"
				:isEditMode="true"
			/>
			<UploadYoutubeRecordedLectureModal
				v-else-if="contentData.type == ContentType.RECORDED_VIDEOS && contentData.externalService.serviceProvider.toLowerCase() == 'youtube'"
				:showModal="showEditModal"
				:parentContentId="parentDirectoryID"
				@updateModalState="toggleEditModal"
				:productVariantId="productVariantId"
				:contentData="contentData"
				:service="contentData.externalService"
			    :contentType="contentData.type"
				:isEditMode="true"
			/>
			<UploadStudyMaterialModal
				v-else-if="contentData.type == ContentType.STUDY_MATERIAL"
				:showModal="showEditModal"
				:parentContentId="parentDirectoryID"
				@updateModalState="toggleEditModal"
				:productVariantId="productVariantId"
				:contentData="contentData"
				:isEditMode="true"
			/>
			<FlashCardActionModal
				v-else-if="contentData.type == ContentType.FLASHCARDS"
				:visible="showEditModal"
				:parentContentId="parentDirectoryID"
				@updateModalState="toggleEditModal"
				:contentData="contentData"
				:isEditMode="true"
			/>
			<FreeTextActionModal
				v-else-if="contentData.type == ContentType.FREETEXT"
				:visible="showEditModal"
				:parentContentId="parentDirectoryID"
				@updateModalState="toggleEditModal"
				:contentData="contentData"
				:isEditMode="true"
			/>
			<CreateEditQuizModal
				v-else-if="contentData.type == ContentType.QUIZ"
				:showModal="showEditModal"
				mode="edit"
				:parentContentId="parentDirectoryID"
				@updateModalState="toggleEditModal" 
				:productVariantId="productVariantId"
				:contentData="contentData"
				:isEditMode="true"
			/>
			<AssignmentActionModal
				v-else-if="contentData.type == ContentType.ASSIGNMENT"
				:isEditMode="true"
				:visible="showEditModal"
				:parentContentId="parentDirectoryID"
				:contentData="contentData"
				@updateModalState="toggleEditModal"
			/>
			<EditContentModal
				v-else
				:contentData="contentData"
				:showModal="showEditModal"
				@updateModalState="toggleEditModal"
				:entity="contentData.type"
				:parentDirectoryID="parentDirectoryID"
			/>
			<b-modal v-if="contentData.type == ContentType.LIVE_CLASS" id="copy-join-url-modal" :visible="showCopyUrlModal"
				hide-header centered content-class="copy-join-url-modal" size="md" body-class="modal-body" hide-footer
				modal-class="custom-modal" @change="toggleCopyUrlModal" >
				<div class="content">
					<h4>Copy Link</h4>
					<div class="input-group mb-3">
						<p class="form-control truncate" >
							<a :href="copyUrlLink" target="_blank">
							{{copyUrlLink}}</a></p>
						<div class="input-group-append">
							<button class="btn btn-primary" type="button" @click="copyJoinUrlToClipBoard"> <i class="fal fa-copy"></i>
						</button>
						</div>
					  </div>
				</div>
			</b-modal>
	</div>
	
</template>

<script>
import formatter from "../../../helpers/FormatHelpers";
import EditContentModal from "./EditContentModal.vue";
import StudyMaterial from "../../Content/StudyMaterial.vue";
import CreateEditExamModal from "../Exam/CreateEditExamModal.vue";
import CreateZoomMeetingModal from "../Content/CreateZoomMeetingModal.vue";
import UploadRecordedLectureModal from "../Content/UploadRecordedLectureModal.vue";
import UploadYoutubeRecordedLectureModal from "../Content/UploadYoutubeRecordedLectureModal.vue";
import UploadStudyMaterialModal from "../Content/UploadStudyMaterialModal.vue";
import LiveClassAttendanceModal from "../Attendance/LiveClassAttendanceModal.vue";
import ContentAttendanceModal from "../Attendance/ContentAttendanceModal.vue";
import ExamQuestionsModal from "../Content/ExamQuestionsModal.vue";
import QuizQuestionsModal from "../Content/QuizQuestionsModal.vue";
import CreateEditLegacyExamModal from "../Exam/CreateEditLegacyExamModal.vue";
import FlashCardActionModal from "./FlashCardActionModal.vue";
import FreeTextActionModal from "./FreeTextActionModal.vue";
import CreateEditQuizModal from "../Exam/Quiz/CreateEditQuizModal.vue";
import ExamActionModal from "./ExamActionModal.vue"
import ManageAssignmentModal from "./ManageAssignmentModal.vue"
import { mapActions, mapState } from "vuex";
import { contentService } from "../../../services/content.service";
import { ContentType } from "../../../constants/content";
import { ServiceCodes } from "../../../constants/serviceCodes";
import { setCookie, getCookie } from "../../../helpers/CookieHelpers";
import { LegacyExam } from "../../../constants/exam";
import ViewQuizQuestionsModal from "./ViewQuizQuestionsModal.vue";
import AssignmentActionModal from "./AssignmentActionModal.vue";
export default {
	name: "CourseContentSectionChild",
	data() {
		
		return {
			// ContentType: {
			// 	DIRECTORY: "directory",
			// 	RECORDED_VIDEOS: ContentType.RECORDED_VIDEOS,
			// 	LIVE_CLASS: ContentType.LIVE_CLASS,
			// 	ASSIGNMENT: ContentType.ASSIGNMENT,
			// 	STUDY_MATERIAL: ContentType.STUDY_MATERIAL,
			// 	EXAM: ContentType.EXAM,
			// },
			showEditModal: false,
			showStudyMaterialModal: false,
			showExamActionModal:false,
			showExamQuestionsModal:false,
			showManageAssignmentsModal:false,
			showQuizQuestionsModal: false,
			examQuestionsModal_Mode:null,
			quizQuestionsModal_Mode:null,
			contentExtraProperties: null,
			showLiveClassAttendanceModal: false,
			examActionModal_Action:null,
			showCopyUrlModal:false,
			copyUrlLink:"",
			showManageAssignmentsModal:false,
			ContentType,
			ServiceCodes,
			LegacyExam,
			showViewQuizQuestionsModal: false
		};
	},
	props: {
		contentData: Object,
		contentProgress: Number,
		contentLoadingId: String,
		parentDirectoryID: String,
		productVariantId: String,
	},
	computed: {
		...mapState("content", ["content", "loading", "isContentSectionSelectable"]),
		...mapState("user", ["user"]),
		contentLink() {
			switch (this.contentData.type) {
				case ContentType.RECORDED_VIDEOS:
					return (
						"/content/video/" +
						this.contentData.title
							.replace(/[^a-zA-Z0-9 ]/g, "")
							.toLowerCase()
							.split(" ")
							.join("-") +
						"-" +
						this.contentData.id +
						"_" +
						this.productVariantId
					);
				case ContentType.ASSIGNMENT:
					return (
						"/content/assignment/" +
						this.contentData.title
							.replace(/[^a-zA-Z0-9 ]/g, "")
							.toLowerCase()
							.split(" ")
							.join("-") +
						"-" +
						this.contentData.id +
						"_" +
						this.productVariantId
					);
			}
		},
		buttonText() {
			switch (this.contentData.type) {
				case ContentType.RECORDED_VIDEOS:
					return "Watch";
				case ContentType.LIVE_CLASS:
					return "Join";
				case ContentType.STUDY_MATERIAL:
					return "View";
				case ContentType.EXAM:
					return "Preview";
				case ContentType.ASSIGNMENT:
					return "Manage";
			}
		},
		showActionButton() {
			switch (this.contentData.type) {
				case ContentType.LIVE_CLASS:
					return (
						Date.now() <
						new Date(this.contentData.availableTo).setHours(
							new Date(this.contentData.availableTo).getHours() + 3
						)
					);
					break;
				case ContentType.EXAM:
					if (
						this.contentData.externalService.serviceProvider ==
						ServiceCodes.EXAM.V3ExamSystem
					) {
						return false;
					}
					break;
				case ContentType.ASSIGNMENT:
					return true;
				case ContentType.RECORDED_VIDEOS:
					return true;
				case ContentType.STUDY_MATERIAL:
					return true;
				default:
					return false;
			}
		},
	},
	methods: {
		...mapActions("content", [
			"deleteContent",
			"getContentById",
			"saveCopiedContent",
			"selectBulkContent",
			"unselectBulkContent",
			"saveBulkCopiedContent"
		]),

		manageContentState(event) {
			if(event){
				if(event.target.checked) {
					this.selectBulkContent(this.contentData);
				}
				else {
					this.unselectBulkContent(this.contentData)
				}
			}
		},

		toggleCopyUrlModal(){
			this.showCopyUrlModal=!this.showCopyUrlModal;
			if (!this.showCopyUrlModal) {
				this.copyUrlLink = null;
			}
		},
		copyJoinUrlToClipBoard(){
			navigator.clipboard.writeText(this.copyUrlLink);
			this.$toasted.success("Link copied to clipboard", {
					duration: 3000,
					theme: "outline",
					icon: "check",
					position: "bottom-center",
				});
		},
		dateParser(date) {
			return formatter.toShortDateString(date);
		},
		timeParser(date) {
			return formatter.getTimeIn12Hours(date);
		},
		examTimeParser(time) {
			
			time=time.replace("00h","");
			time=time.replace("00m","");			
			time=time.replace("00s","");
			time=time.replace("h"," hr ");
			time=time.replace("m"," min ");
			time=time.replace("s"," sec ");
			return time;
		},
		toggleEditModal(state) {
			if (state !== undefined) {
				this.showEditModal = state;
			} else this.showEditModal = !this.showEditModal;
		},
		showModal() {
			this.showEditModal = true;
		},
		showDeleteConfirmPopup(e) {
			e.preventDefault();
			e.stopPropagation();
			this.$bvModal
				.msgBoxConfirm(
					`Are you sure you want to delete Content: ${this.contentData.title}?`,
					{
						title: "Are you sure?",
						size: "md",
						buttonSize: "md",
						okVariant: "danger",
						okTitle: "Yes",
						cancelTitle: "No",
						footerClass: "p-2",
						hideHeaderClose: false,
						centered: true,
						bodyClass: "confirm-modal-body",
					}
				)
				.then((value) => {
					// this.isConfirm = value;
					if (value) {
						this.contentDelete();
					}
				})
				.catch((err) => {
					console.error(err);
					// An error occurred
				});
		},
		contentDelete() {
			var contentObj = {
				...this.contentData,
				parentDirectoryID: this.parentDirectoryID,
				entity: this.contentData.type,
			};

			this.deleteContent(contentObj).then(() => {
				console.log("Content deleted");
			});
		},
		joinClass() {
			// var joinURLFromCookies = getCookie(
			// 	`${this.contentData.id}_${this.user.id}`
			// );
			// if (joinURLFromCookies) {
			// 	console.log({ joinURLFromCookies });
			// 	window.open(joinURLFromCookies, "_blank");
			// 	return;
			// }
			this.getContentById({
				id: this.contentData.id,
				extraParams: { Platform: contentService.getCurrentPlatform() },
			}).then(() => {
			
				window.open(
					this.content[this.contentData.id].data.joining_Url,
					"_blank"
				);
			});
		},
		
		copyJoinLink() {
			this.getContentById({
				id: this.contentData.id,
				extraParams: { Platform: contentService.getCurrentPlatform(), For: "student" },
			}).then(() => {
				console.log(this.content[this.contentData.id].data.joining_Url);
				this.copyUrlLink=this.content[this.contentData.id].data.joining_Url;
				if(this.showCopyUrlModal){
					this.showCopyUrlModal=false;
				}
				this.showCopyUrlModal=true;
			});
		},
		showVideo() {
			console.log("showVideo");
			this.getContentById({
				id: this.contentData.id,
				extraParams: { Platform: contentService.getCurrentPlatform() },
			}).then(() => {
				window.open(this.contentLink, "_blank");
			});
		},
		previewExam() {
			this.getContentById({
				id: this.contentData.id,
				extraParams: { RedirectUrl: window.location.href },
			}).then((res) => {
				window.location.href = `http://examapp.gocbeglobal.com/exam/attempt?examPreview=true&TestID=${res.data.examID}`;
			});
		},
		handleClick() {
			console.log("handleClick", this.contentData.type);
			switch (this.contentData.type) {
				case ContentType.RECORDED_VIDEOS:
					this.showVideo();
					return;
				case ContentType.LIVE_CLASS:
					this.joinClass();
					return;
				case ContentType.STUDY_MATERIAL:
					this.toggleStudyMaterialModal(true);
					return;
				case ContentType.ASSIGNMENT:
					this.toggleManageAssignmentsModal(true);
					return;
				case ContentType.EXAM:
					this.previewExam();
					return;
				case ContentType.ASSIGNMENT:
					this.toggleManageAssignmentsModal(true);
					return;
			}
		},
		toggleStudyMaterialModal(state) {
			if (state !== undefined) {
				this.showStudyMaterialModal = state;
			} else {
				this.showStudyMaterialModal = !this.showStudyMaterialModal;
			}
		},
		toggleManageAssignmentsModal(state) {
			if (state !== undefined) {
				this.showManageAssignmentsModal = state;
			} else {
				this.showManageAssignmentsModal = !this.showManageAssignmentsModal;
			}
		},
		
		viewExamResults() {},
		toggleLiveClassAttendanceModal(state) {
			if (state === undefined) {
				this.showLiveClassAttendanceModal = !this.showLiveClassAttendanceModal;
			} else this.showLiveClassAttendanceModal = state;
		},

		toggleExamQuestionsModal(isOpen,mode) {
			if (isOpen === undefined) {
				this.showExamQuestionsModal = !this.showExamQuestionsModal;
			} else this.showExamQuestionsModal = isOpen;
            
			if(mode){
				this.examQuestionsModal_Mode=mode;
			}


		},

		toggleQuizQuestionsModal(isOpen,mode) {
			if (isOpen === undefined) {
				this.showQuizQuestionsModal = !this.showQuizQuestionsModal;
			} else this.showQuizQuestionsModal = isOpen;
            
			if(mode){
				this.quizQuestionsModal_Mode=mode;
			}


		},
		
		toggleExamActionModal(isOpen,action) {
			if (isOpen === undefined) {
				this.showExamActionModal = !this.showExamActionModal;
			} else this.showExamActionModal = isOpen;
            
            if(action){
				this.examActionModal_Action=action;
			}
		},
		calculatedDuration() {
			var startTime = new Date(this.contentData.availableFrom).getTime();
			var endTime = new Date(this.contentData.availableTo).getTime();
			var diff = endTime - startTime;
			return diff / (1000 * 60);
		},
		copyContent(e) {
			if (e) e.stopPropagation();
			console.log("copyContent", this.contentData.id);
			this.saveBulkCopiedContent(this.contentData).then(() => {
				this.$toasted.success("Content copied", {
					duration: 5000,
					theme: "outline",
					icon: "check",
					position: "bottom-center",
				});
			});
		},

		toggleViewQuizQuestionsModal(state) {
			if (state === undefined) {
				this.showViewQuizQuestionsModal = !this.showViewQuizQuestionsModal;
			} else this.showViewQuizQuestionsModal = state;
		}
	},
	components: {		
		LegacyExam,
		EditContentModal,
		StudyMaterial,
		CreateEditExamModal,
		CreateZoomMeetingModal,
		CreateEditLegacyExamModal,
		UploadRecordedLectureModal,
		UploadStudyMaterialModal,
		LiveClassAttendanceModal,
		ContentAttendanceModal,
		ExamQuestionsModal,
		ExamActionModal,
		FlashCardActionModal,
		FreeTextActionModal,
		ManageAssignmentModal, 
		QuizQuestionsModal,
		CreateEditQuizModal,
		ViewQuizQuestionsModal,
		AssignmentActionModal,
		UploadYoutubeRecordedLectureModal
	},
	created() {
		if (this.contentData && this.contentData.extraProperties != null) {
			this.contentExtraProperties =typeof(this.contentData.extraProperties)=='string'?
			 JSON.parse(
				this.contentData.extraProperties
			 ):this.contentData.extraProperties;
		}
	},
	watch: {
		contentData: function () {
			if (this.contentData.extraProperties != null) {
				this.contentExtraProperties =
					// JSON.parse(
					this.contentData.extraProperties;
				// );
			}
		},
	},
};
</script>

<style scoped>
@keyframes pulse {
	0% {
		background-color: #fff;
	}

	50% {
		background-color: #e1f5fd;
	}

	100% {
		background-color: #fff;
	}
}

.list-item {
	display: flex;
	flex-direction: row;
	align-items: center;
	border-bottom: 1px solid var(--color-light-gray);
	cursor: pointer;
	font-size: 1rem;
	padding: 1rem;
}

.list-item.processing {
	opacity: 0.5;
	pointer-events: none;
}

.list-item.new {
	animation: pulse 1.5s ease 1;
}

.content-item-icon {
	/* margin-left: 0.25rem; */
	margin-right: 0.5rem;
	font-size: 1rem;
	color: var(--color-text-default);
}

.page-view-icon {
	font-size: 0.9rem;
	/* width: 32px; */
	height: 36px;
	/* border-radius: 50%; */
	display: flex;
	align-items: center;
	justify-content: center;
}

.page-view-icon.blue-bg {
	background-color: #2d8bfe;
	color: var(--color-white);
}

.content-icon-border {
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 1rem;
	/* border: 2px solid #d5d5d5; */
	border-radius: 50%;
}

.demo-tag {
	border-radius: 6px;
	padding: 0.25rem 0.5rem;
	text-transform: uppercase;
	color: #ffffff;
	font-size: 0.75rem;
	font-weight: normal;
	line-height: 1;
	width: fit-content;
	background-color: var(--primary-color);
}

.content-list .right-menu {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 1rem;
	margin-left: auto;
	margin-right: 0.75rem;
	transition: opacity 0.3s, visibility 0s ease;
	visibility: hidden;
	opacity: 0;
}

.content-list .list-item:hover .right-menu {
	visibility: visible;
	opacity: 1;
}

/* .content-list .list-item {
	cursor: pointer;
} */
.content-list .list-item .drag-icon.handle {
	cursor: grab;
}

.right-menu-icon {
	width: 2rem;
	height: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	border: 0.8px solid #cbcbcb;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.07);
	border-radius: 50%;
	color: #000000;
	text-decoration: none;
}

.right-menu-icon.delete {
	border-color: #ff0000;
}



/* .right-menu-icon img {
	max-height: 0.75rem;
} */

.content-cta button {
	padding: 0.425rem 0;
	width: 5.75rem;
	line-height: 1;
	border-color: #282828;
	font-size: 0.875rem;
	border-radius: 4px;
	color: #282828 !important;
	font-weight: 500;
}

.content-cta button.preview {
	padding: 0.425rem 0.675rem;
	width: fit-content;
}
</style>
