<template>
  <div class="study-material-div">
      
      <div class="download-btn-div ms-3 mt-3" v-if="isContentTypeLink">
        <a class="btn btn-primary" target="_blank" :href="contentData.data.Source">Click to View Content</a>
      </div>

      <div v-else id="document-div" class="row" style="height: inherit;" >
        <h4 v-if="isPDFLoading" style="position: absolute;z-index: -1;" class="px-4 py-3">Loading document, please wait...</h4>

        <!--
          PDFJS Viewer will be shown inside iFrame when downloadAllowed is either false
          or user is using through mobile
        -->
        <iframe v-if="type == 'pdf' && PDFJSViewerUrl && (isMobile || !isDownloadAllowed)" @change="hideShare" @loadeddata="hideShare" id="no-click" :src="PDFJSViewerUrl" frameborder="0"
                width="100%" height="100%" @contextmenu="disableRightClick" tabindex="1" @load="iframeLoadEvent"
                sandbox="allow-scripts allow-same-origin allow-forms"></iframe>

        <PDFExpressViewer v-else-if="type == 'pdf' && !isDownloadAllowed && pdfJsExpressApiKey && !isMobile"
          :path="`${publicPath}lib`" :url="iframeLink" :apiKey="pdfJsExpressApiKey" />

        <embed id="no-click" v-else-if="type == 'pdf' && isDownloadAllowed && !isMobile"
          :src="`${iframeLink}#embedded=true&toolbar=0&navpanes=0`" frameborder="0" navpanes="0" width="100%"
          height="100%" />

        <AdobePDFViewer v-else-if="type == 'pdf' && isDownloadAllowed && isMobile && AdobePDFReaderAPIKey" :apiKey="AdobePDFReaderAPIKey" :source="src" :fileName="contentData.title"  />

        <!--
          Following iFrame will be used for non-PDF docs.
          Or, it will be used as fallback if AllowDownload of PDF is set to false
          and PDFExpress/AdobeViewer/PDFJSViewer none of them are configured for current tenant
        -->
        <iframe v-else @change="hideShare" @loadeddata="hideShare" id="no-click" :src="iframeLink" frameborder="0"
          width="100%" height="100%" @contextmenu="disableRightClick" tabindex="1" @load="iframeLoadEvent"
          sandbox="allow-scripts allow-same-origin allow-forms"></iframe>

      </div>
      <!--      <div class="overlay"></div>-->
      <!-- <div class="row">
        <div class="download-btn-div" v-if="this.isDownloadAllowed">
          <button class="btn btn-primary" @click="handleDownloadDocumentButtonClick">Download</button>
        </div>
      </div> -->
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { default as download } from 'downloadjs';
//import pdf from 'vue-pdf';
import PDFExpressViewer from "@/components/Content/PDFExpressViewer";
import AdobePDFViewer from "../../PDFViewer/AdobePDFViewer.vue";

export default {
  name: "StudyMaterial",
  components: {
    //pdf,
    PDFExpressViewer,
    AdobePDFViewer
  },
  data() {
    return {
      type: null,
      src: null,
      isDownloadAllowed: false,
      numberPages: 1,
      isPDFLoading: true,
      publicPath: process.env.BASE_URL,
      isContentTypeLink: false
    };
  },
  props: {
    contentData: Object,
  },
  methods: {
    ...mapActions("content", ["getContentById"]),
    closeModal() {
      Object.assign(this.$data, this.$options.data.call(this));
      this.$emit("updateModalState", false);
    },
    handleRightClick(e) {
      console.log("handle right click");
      e.preventDefault();
      return false;
    },
    hideShare() { },

    handleDownloadDocumentButtonClick(e) {
      download(this.src);
    },
    randomNumber(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
    disableRightClick(e) {
      e.preventDefault();
    },
    iframeLoadEvent(e) {
      this.isPDFLoading = false;
      console.log('iframe sucessfullyy loaded')
      console.log({ e });
    }
  },
  computed: {
    ...mapState("appState", ["isMobile", "systemParameters"]),
    visible() {
      return this.showModal;
    },
    iframeLink() {

      if (this.type == "document") {
        return `https://view.officeapps.live.com/op/embed.aspx?src=${this.src}`;
      }

      if (this.type == "pdf" && !this.isDownloadAllowed && !this.pdfJsExpressApiKey) {
        let src = `https://docs.google.com/viewer?embedded=true&url=${this.src}?randomizer=${this.randomNumber(1, 10000)}`;
        return src;

        // var loadingTask = pdf.createLoadingTask(this.src);
        //
        // this.isPDFLoading = true;
        //
        // loadingTask.promise.then(pdf => {
        //   this.numberPages=pdf.numPages;
        //   console.log(pdf);
        //   this.isPDFLoading = false;
        //   document.getElementById('document-div').focus();
        // });
      } else if (this.type == "pdf" && this.isMobile && !this.AdobePDFReaderAPIKey) {
        return `https://docs.google.com/viewer?embedded=true&url=${this.src}?randomizer=${this.randomNumber(1, 10000)}`;
      }
      return this.src;
    },
    pdfJsExpressApiKey() {
      return this.systemParameters.PDFJSExpressAPIKey
        ? this.systemParameters.PDFJSExpressAPIKey
        : undefined;
    },
    AdobePDFReaderAPIKey() {
      return this.systemParameters.AdobePDFReaderAPIKey
        ? this.systemParameters.AdobePDFReaderAPIKey
        : undefined;
    },

    PDFJSViewerUrl() {
      const baseUrl = this.systemParameters && this.systemParameters.PDFJSVIEWERURL
      if(!baseUrl) return null;
      return `${baseUrl}?pdfUrl=${encodeURIComponent(this.src)}`;
    },
  },
  created() {

    if (this.contentData.externalService.serviceProvider.toLowerCase() == "vss") {
      this.src = this.contentData.data?.viewUrl;
    } else {
      if (this.contentData.data?.Type == "link") {
        this.isContentTypeLink = true;
        this.isPDFLoading = false;
      } else {
        this.type = this.contentData.data?.Type;
        this.src = this.contentData.data?.Source;
        this.isDownloadAllowed = this.contentData.data?.AllowDownload?.toString().toLowerCase() == "true" ? true : false;
      }
    }
  },
  mounted() {
    $("#no-click").on("load", function () {
      console.log("hideShare", $("[data-tooltip='Pop-out']"));
    });
    // console.log($("#view-study-material-modal"));
  },
  // beforeDestroy() {
  // 	$("body").off("onmousedown");
  // },
  watch: {
    contentData: function () {

      if (this.contentData.externalService.serviceProvider.toLowerCase() == "vss") {
        this.src = this.contentData.data?.viewUrl;
      } else {
        if (this.contentData.data?.Type == "link") {
          this.isContentTypeLink = true;
          this.isPDFLoading = false;
        } else {
          this.type = this.contentData.data?.Type;
          this.src = this.contentData.data?.Source;
          this.isDownloadAllowed = this.contentData.data?.AllowDownload?.toString().toLowerCase() == "true" ? true : false;
        }
      }
    }
  },
};
</script>

<style>
@media screen and (min-width: 996px) {
  .view-study-material-modal {
    height: fit-content !important;
    width: 90vw !important;
  }

  #view-study-material-modal .modal-dialog {
    max-width: 90vw !important;
  }
}

@media screen and (min-width: 996px) {
  .view-study-material-modal {
    height: fit-content !important;
    width: 95vw !important;
  }

  #view-study-material-modal .modal-dialog {
    max-width: 90vw !important;
  }
}


.view-study-material-modal {
  height: 80vh !important;
  margin: 10px;
}

/* .view-study-material-modal {
	height: 90vh;
	width: 90vw !important;
}
#view-study-material-modal .modal-dialog {
	max-width: 90vw !important;
} */

.view-study-material-modal .modal-body {
  display: flex;
  flex-direction: row;
  padding: 0 !important;
  font-size: 1rem;
  overflow: hidden;
  height: inherit;
}

.view-study-material-modal .modal-body .body-container {
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  height: inherit;
}

.document-div {
  overflow: scroll;
  height: inherit;
}

.document-div::-webkit-scrollbar {
  width: 1.5rem !important;
  height: inherit !important;
}


.document-div::-webkit-scrollbar-thumb {
  border-radius: 0px !important;
  background: rgb(0 0 0 / 35%) !important;
}

.document-div::-webkit-scrollbar-thumb:hover {
  background: rgb(0 0 0 / 60%) !important;
}

.view-study-material-modal .modal-body .body-container .download-btn-div {
  margin: 10px;
}

.view-study-material-modal .modal-body .body-container .close-button {
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 99;
  background-color: #ffffff;
  padding: 0.75rem;
  border-radius: 50%;
  border: 2px solid var(--primary-color);
  box-shadow: 0 3px 6px #d5d5d529;
}

.close-button img {
  width: 1.5rem;
}

.pdf-viewer {
  height: fit-content;
}

.overlay {
  position: absolute;
  top: 0;

  height: 5rem;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
}

.loading {
  margin-top: 250px;
  margin-bottom: 100px;
}

@media print {
  .document-div {
    display: none !important;
  }
}

/* #no-click {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	z-index: 1250;
	pointer-events: click;
} */
</style>

<style scoped>
.study-material-div{
  height: calc(100% - 1px);
}

@media (max-width:768px){
  .study-material-div{
    height: calc(100vh - 140px);
    width: calc(100% - 50px);
  }  
  .study-material-div iframe{
    padding: 0;
  }
}
</style>
