var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard-updates-container"},[(_vm.filteredUpcomingContent().length > 0)?_c('div',{staticClass:"updates-card"},[_c('ul',{staticClass:"inline-list updates-tabs"},[_c('li',{class:[{ active: _vm.activeTab == 'reminder' }],attrs:{"id":"reminder"},on:{"click":_vm.changeTab}},[_c('i',{staticClass:"far fa-clock tab-icon"}),_vm._v(" Reminders "),_c('span',{staticClass:"notification-badge"},[_vm._v(_vm._s(_vm.totalReminderSlides))])])]),(_vm.activeTab == 'announcement')?_c('div',{staticClass:"announcements-content"},[_c('div',{staticClass:"accordion accordion-flush",attrs:{"id":"announcementAccordian"}},_vm._l((1),function(item){return _c('div',{key:item,staticClass:"accordion-item"},[_c('h2',{staticClass:"accordion-header",attrs:{"id":`accordian-${item}`}},[_c('button',{class:[
								'accordion-button',
								{ collapsed: _vm.activeAnnouncementAccordian != item },
							],attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":`#accordian-collapse-${item}`,"aria-expanded":_vm.activeAnnouncementAccordian == item}},[_vm._v(" Today ")])]),_c('div',{class:[
							'accordion-collapse',
							{
								collapse: _vm.activeAnnouncementAccordian != item,
								show: _vm.activeAnnouncementAccordian == item,
							},
						],attrs:{"id":`accordian-collapse-${item}`,"data-bs-parent":"#announcementAccordian"}},[_c('div',{staticClass:"accordion-body"},[_c('div',{staticClass:"announcements-list"},_vm._l((5),function(item){return _c('div',{key:item,staticClass:"announcement-item"},[_c('h4',{staticClass:"announcement-title"},[_vm._v(" This course class teacher will be continue from Another teacher from next class ")]),_c('p',{staticClass:"announcement-text"},[_vm._v(" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore ")]),_c('a',{staticClass:"announcement-read-more",attrs:{"role":"button"}},[_vm._v("Read more")])])}),0)])])])}),0)]):_vm._e(),(_vm.activeTab == 'reminder')?_c('div',{class:['reminders-content', { mobile: _vm.isMobile }]},[(!_vm.isMobile)?_c('div',{staticClass:"accordion accordion-flush",attrs:{"id":"reminderAccordian"}},_vm._l((Object.values(_vm.UpcomingContentType)),function(contentType){return _c('div',{key:contentType,staticClass:"accordion-item"},[_c('h2',{staticClass:"accordion-header",attrs:{"id":`accordian-${contentType}`}},[_c('button',{class:[
								'accordion-button',
								{ collapsed: _vm.activeAnnouncementAccordian != contentType },
							],attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":`#accordian-collapse-${contentType}`,"aria-expanded":_vm.activeAnnouncementAccordian == contentType}},[_vm._v(" "+_vm._s(contentType)+" Events ")])]),_c('div',{class:['accordion-collapse'],attrs:{"id":`accordian-collapse-${contentType}`,"data-bs-parent":"#reminderAccordian"}},[_c('div',{staticClass:"accordion-body"},[(
									_vm.filteredUpcomingContent(contentType).length > 0 && !_vm.loading
								)?_c('div',{staticClass:"reminders-list"},_vm._l((_vm.filteredUpcomingContent(contentType)),function(item){return _c('ReminderListItem',{key:item.id,attrs:{"reminderItem":item}})}),1):_c('div',{staticClass:"no-content"},[_vm._v(" No "+_vm._s(contentType.toLowerCase())+" events ")])])])])}),0):_c('div',[_c('VueSlickCarousel',_vm._b({ref:"reminder_carousel",on:{"afterChange":_vm.updateSlide},scopedSlots:_vm._u([{key:"prevArrow",fn:function(arrowOption){return [_c('div',{staticClass:"custom-arrow"},[_vm._v(" "+_vm._s(arrowOption.currentSlide)+"/"+_vm._s(arrowOption.slideCount)+" ")])]}}],null,false,2816469163)},'VueSlickCarousel',_vm.settings,false),[_vm._l((_vm.filteredUpcomingContent(
							_vm.UpcomingContentType.CURRENT
						)),function(item){return _c('ReminderListItem',{key:item.id,attrs:{"reminderItem":item}})}),_vm._l((_vm.filteredUpcomingContent(
							_vm.UpcomingContentType.UPCOMING
						)),function(item){return _c('ReminderListItem',{key:item.id,attrs:{"reminderItem":item}})}),_vm._l((_vm.filteredUpcomingContent(_vm.UpcomingContentType.PAST)),function(item){return _c('ReminderListItem',{key:item.id,attrs:{"reminderItem":item}})})],2),_c('div',{staticClass:"carousel-controls"},[_c('span',{staticClass:"carousel-slide-count"},[_vm._v(" "+_vm._s(_vm.currentReminderSlide)+" of "+_vm._s(_vm.totalReminderSlides)+" ")]),(_vm.totalReminderSlides > 1)?_c('div',{staticClass:"carousel-arrows"},[_c('a',{staticClass:"carousel-arrow-mobile",attrs:{"role":"button"},on:{"click":_vm.prevSlide}},[_c('i',{staticClass:"fas fa-caret-left"})]),_c('a',{staticClass:"carousel-arrow-mobile",attrs:{"role":"button"},on:{"click":_vm.nextSlide}},[_c('i',{staticClass:"fas fa-caret-right"})])]):_vm._e()])],1)]):_vm._e()]):_vm._e(),(_vm.loading && !_vm.filteredUpcomingContent().length)?_c('div',{staticClass:"updates-card"},[_c('b-skeleton',{attrs:{"height":"25rem","width":"100%"}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }