<template>
	<div class="student-panel-header">
		<header>
			<div id="nav" class="nav-container">
				<div class="logo-container">
					<a
						@click="toggleProductDropdown"
						role="button"
						class="my-nav-link nav-link-mobile hamburger dropdown-toggle"
						id="coursesMenu"
						data-bs-toggle="dropdown"
						aria-expanded="false"
						><object
							:data="require(`@/${HEADER_HAMBURGER_PATH}`)"
							alt=""
							type="image/svg+xml"
							class="color-change-svg"
					/></a>
					<ProductDropdown
						:showModal="productDropdown"
						@close-dropdown="closeProductDropdown"
					/>
					<router-link :to="'/'" class="d-flex logo-link"
						><img class="logo" :src="logoPath"
					/></router-link>
				</div>
				<a
					@click="toggleProductDropdown"
					type="button"
					class="courses-btn dropdown-toggle"
					id="coursesMenu"
					data-bs-toggle="dropdown"
					aria-expanded="false"
					>{{ HEADER_COURSES
					}}<object
						:data="require(`@/${HEADER_HAMBURGER_PATH}`)"
						alt=""
						type="image/svg+xml"
						class="ms-2 color-change-svg"
					/>
				</a>
				<ProductDropdown
					:showModal="productDropdown"
					@close-dropdown="closeProductDropdown"
				/>
				<Searchbar />
				<div v-if="loggedIn" id="header-links-login" class="header-links abc">
					<router-link
						class="my-nav-link first desktop-only"
						to="/user/dashboard"
					>
						My Courses
					</router-link>
					<button
						@click="showPDF"
						v-if="loggedIn && systemParameters.StudentKnowledgeBase"
						class="my-nav-link desktop-only"
						to="#"
					>
						Knowledgebase
					</button>
					<button						
						v-if="loggedIn"
						type="button" 
						@click="showHideNotification"						
						class="my-nav-link header-border-btn notification-icon nav-link-mobile"
						to="#"
					>			 
						<i class="fa fa-bell-o"></i>
					</button>
					<router-link to="/search" class="header-border-btn nav-link-mobile">
						<img src="@/assets/images/search-icon.svg" />
					</router-link>
					<router-link class="header-border-btn cart" to="/shop/checkout">
						<img
							:src="require(`@/${HEADER_CART_ICON}`)"
							class="header-btn-icon"
						/>
						<span class="cart-icon-badge">
							{{ cartItemsNumber }}
						</span>
					</router-link>
					<a
						id="userMenu"
						role="button"
						data-bs-toggle="dropdown"
						aria-expanded="false"
						:class="['header-secondary-btn avatar dropdown-toggle']"
					>
						<div class="user-avatar-holder">
							<img
								class="user-avatar"
								v-if="user.profilePicture"
								:src="user.profilePicture"
							/>
							<span class="user-avatar" v-else>{{ user.firstName[0] }}</span>
						</div>
					</a>
					<div
						class="dropdown-menu custom-user-dropdown"
						aria-labelledby="coursesMenu"
					>
						<a
							role="button"
							class="custom-dropdown-item"
							@click="handleLinkClick('/user/dashboard')"
						>
							My Courses
						</a>
						<a
							role="button"
							class="custom-dropdown-item"
							@click="handleLinkClick('/user/profile')"
						>
							Profile
						</a>
						<!-- <a
							role="button"
							class="custom-dropdown-item"
							@click="handleLinkClick('/shop/checkout')"
						>
							My Purchases
						</a> -->
						<a
							role="button"
							class="custom-dropdown-item"
							@click="handleLinkClick('/user/invoices')"
						>
							Invoices
						</a>
						<a
							role="button"
							class="custom-dropdown-item"
							@click="handleLinkClick('/support')"
						>
							Complaint And Help Center
						</a>
						<a
							role="button"
							class="custom-dropdown-item"
							@click="handleLinkClick('logout')"
						>
							Logout
						</a>
					</div>
				</div>
				<div
					v-if="!loggedIn"
					class="flex-grow-1 header-links"
					id="header-links"
				>
					<router-link class="header-border-btn cart" to="/shop/checkout">
						<img
							:src="require(`@/${HEADER_CART_ICON}`)"
							class="header-btn-icon"
						/>
						<span class="cart-icon-badge">
							{{ cartItemsNumber }}
						</span>
					</router-link>
					<router-link class="header-secondary-btn" to="/account/login" >{{
						HEADER_LOGIN
					}}</router-link>
					<router-link
						class="header-primary-btn"
						to="/account/register"
						>{{ HEADER_REGISTER }}</router-link
					>
				</div>
			</div>
		</header>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import ProductDropdown from "../Product/ProductDropdown/ProductDropdown.vue";
import Searchbar from "./Searchbar.vue";
import {
	HEADER_DASHBOARD,
	HEADER_LOGIN,
	HEADER_REGISTER,
	HEADER_COURSES,
	HEADER_PROFILE,
	HEADER_LOGOUT,
} from "../../constants/globalTextConstants";

import {
	LOGO_LIGHT_PATH,
	HEADER_HAMBURGER_PATH,
	HEADER_CART_ICON,
} from "../../constants/urls";
export default {
	name: "Header",
	data() {
		return {
			userDropdown: true,
			productDropdown: false,
			HEADER_DASHBOARD,
			HEADER_LOGIN,
			HEADER_REGISTER,
			HEADER_COURSES,
			HEADER_PROFILE,
			HEADER_LOGOUT,
			LOGO_LIGHT_PATH,
			HEADER_HAMBURGER_PATH,
			HEADER_CART_ICON,
		};
	},
	computed: {
		...mapState("user", ["loggedIn", "user"]),
		...mapGetters("cart", ["cartItemsNumber"]),
		...mapState("appState", ["systemParameters"]),
		logoPath() {
			return this.systemParameters.LogoPath
				? this.systemParameters.LogoPath
				: require("@/assets/images/gocbe_logo.png");
		},
		isWebDisabled(){
			return this.systemParameters && this.systemParameters.IsWebAccessDisabled=='1';
		}
	},
	methods: {
		...mapActions("user", ["logout"]),
		// toggleLoginDropdown() {
		// 	this.loginDropdown = !this.loginDropdown;
		// 	this.searchDropdown = false;
		// },
		// toggleSearchDropdown() {
		// 	this.searchDropdown = !this.searchDropdown;
		// 	this.loginDropdown = false;
		// },
		toggleProductDropdown() {
			this.productDropdown = !this.productDropdown;
		},
		closeProductDropdown() {
			this.productDropdown = false;
		},
		handleLinkClick(link) {
			// this.closeDropdowns();
			if (link == "logout") {
				this.logout();
				return;
			} else {
				this.$router.push(link);
			}
		},
		showPDF() {
			if (this.user.type && this.systemParameters.StudentKnowledgeBase)
				this.$emit("openPDF", this.systemParameters.StudentKnowledgeBase);
		},
		showHideNotification() {
			document.getElementById("coursesNotification").classList.toggle("active");
		},
	},
	components: {
		ProductDropdown,
		Searchbar,
	},
	mounted() {
		this.$nextTick(() => {
			// setTimeout(() => {
			window.postMessage({ event: "header-loaded", data: null });
			// }, 1000);
		});
	},
};
</script>

<style scoped>
@media screen and (max-width: 996px) {
	.courses-btn {
		display: none !important;
	}
	.nav-link-mobile {
		display: flex !important;
		align-items: center !important;
		text-transform: none !important;
	}
	/* .searchbar {
		display: none !important;
	} */

	.dropdown-menu-custom {
		display: block !important;
	}
	.my-nav-link {
		font-size: 1rem;
		padding: 0.5rem !important;
	}
	#nav {
		padding: 0 0.5rem !important;
	}

	#nav .logo {
		height: 40px !important;
	}

	#nav .logo-container {
		height: 40px !important;
	}

	.desktop-only {
		display: none !important;
	}
}

@media screen and (max-width: 480px) {
	.header-border-btn,
	.header-secondary-btn {
		margin-right: 0.5rem !important;
	}

	#nav .logo-container {
		margin-right: 1rem !important;
	}

	.my-nav-link {
		margin-right: 0 !important;
	}
}

#nav .logo {
	height: 56px;
}

.nav-container {
	display: flex;
	width: 100%;
	padding-right: 2.5rem;
	padding-left: 2.5rem;
	align-items: center;
}

div.student-panel-header {
  position: absolute !important;
  top: 0 !important;
  z-index: 999999 !important;
  background: white !important;
  width: 100% !important;
}

header {
	display: flex !important;
	box-shadow: 6px 3px 6px #b9b9b929;
	height: var(--header-height);
	align-items: center;
}

.courses-btn {
	padding: 1.125rem 1.5rem;
	background-color: #f8f8f8;
	border: 1px solid #a3a3a3;
	border-radius: 8px;
	box-shadow: 0 3px 6px #d5d5d529;
	font-size: 1.125rem;
	line-height: 1.25;
	margin-right: 1.25rem;
	height: fit-content;
	display: flex;
	align-items: center;
}

.courses-btn img {
	margin-left: 0.75rem;
	width: 1.188rem;
	height: 1.188rem;
}

.header-secondary-btn {
	background-color: #f8f8f8;
	border: 1px solid #e9e9e9;
	border-radius: 8px;
	box-shadow: 0 3px 6px #d5d5d529;
	padding: 1.25rem 1.75rem;
	line-height: 1.25;
	margin-right: 1.25rem;
}

.header-secondary-btn.avatar {
	padding: 0.75rem 0.875rem;
	margin: 0;
}

.header-primary-btn {
	background-color: var(--primary-color);
	border: 1px solid #e9e9e9;
	border-radius: 8px;
	box-shadow: 0 3px 6px #d5d5d529;
	padding: 1.25rem 1.75rem;
	line-height: 1.25;
	color: #ffffff !important;
}

.header-border-btn {
	background-color: #ffffff;
	border: 1px solid #e9e9e9;
	border-radius: 8px;
	box-shadow: 0 3px 6px #d5d5d529;
	padding: 1rem;
	line-height: 1.25;
	margin-right: 1.25rem;
}

.header-border-btn.cart {
	position: relative;
}

.my-nav-link {
	display: inline-flex;
	font-size: 0.875rem;
	/* padding: 2rem 1rem; */
	margin-right: 1.25rem;
	color: #231f20 !important;
	align-items: center;
	height: fit-content;
	text-decoration: underline !important;
}

.my-nav-link.first {
	margin-left: 1.25rem;
}

.my-nav-link.right-border {
	border-right: 1px solid #e9e9e9;
	padding-right: 1.25rem;
}

/* .user-avatar-link {
	padding: 2.125rem 1.7rem;
}

.user-avatar-link.active {
	background-color: #f7f7f7;
} */

.icon-link {
	font-size: 1.75rem;
	padding: 0.5rem 1.25rem;
}

.header-btn-icon {
	height: 1.75rem;
	font-size: 1.75rem;
}

#nav .logo-container {
	display: flex;
	height: 56px;
	margin-right: 1.85rem;
}

#nav a {
	/* text-transform: uppercase; */
	color: var(--color-text-default);
	text-decoration: none;
	font-weight: 500;
	height: 42px;
	line-height: 40px;
	padding: 0 1.25rem;
}
#nav .logo-link{
	padding-left:0;
	height:52px;
	display: flex;
	align-items: center;
}

/* #nav a.router-link-exact-active {
	color: var(--primary-color);
} */

/* .searchbar {
	flex-grow: 16;
	font-size: 1rem;
}

.searchbar-mobile {
	height: 36px;
	border: none;
	outline: none;
	padding: 12px 36px 12px 24px;
	width: 100%;
}

#search {
	line-height: 1.5;
	border: 1px solid #a3a3a3;
	border-radius: 8px;
	background-color: #fafafa;
	outline: none;
	padding: 1.125rem 1.5rem;
	width: 100%;
	box-shadow: 0 3px 6px #dbdbdb29;
}

.search-icon {
	font-size: 18px;
	position: absolute;
	margin-right: 1.5rem;
	right: 0;
} */

.hamburger {
	padding: 0;
	padding-right: 1rem !important;
	font-size: 1.2rem;
	align-self: center;
}

.nav-link-mobile {
	display: none;
}

.dropdown-toggle::after {
	display: none;
}

/* .dropdown-menu-custom {
	background-color: white;
	border-top: 1px solid #cccccc;
	border-bottom: 1px solid #cccccc;
} */

.cart-icon-badge {
	position: absolute;
	top: -0.5rem;
	right: -0.5rem;
	font-size: 0.8rem;
	line-height: 1;
	background-color: var(--color-success);
	display: flex;
	align-items: center;
	justify-content: center;
	/* padding: 0.2rem 0.3rem; */
	width: 1.125rem;
	height: 1.125rem;
	border-radius: 50%;
	font-weight: 500;
}

.header-links {
	display: flex;
	justify-content: flex-end;
	flex-grow: 1;
	align-items: center;
}

.user-avatar {
	width: 2.25rem;
	height: 2.25rem;
	color: var(--color-white);
	background-color: #231f20;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 1;
	font-size: 1.2rem;
	min-width: 2.25rem;
	object-fit: contain;
	/* z-index: 10; */
}
.user-avatar-holder{
	height: 42px;
	display: flex;
	align-items: center;
}

.custom-user-dropdown {
	background-color: #ffffff;
	width: 21.25rem;
	box-shadow: 6px 3px 6px #b9b9b929;
	z-index: 999;
}

.custom-dropdown-item {
	display: flex;
	padding: 1.125rem;
	font-size: 1.125rem;
	font-weight: normal !important;
}

.custom-dropdown-item:hover {
	background-color: #f7f7f7;
}

.dropdown-toggle.show {
	display: flex !important;
}

@media (max-width:768px){
	#nav a {
		padding: 0 0.55rem;
	}
	#nav .notification-icon{
		height:42px;
		line-height: 42px;
		margin-right: 0.5rem !important;
		width: 38px;
	    justify-content: center;
	}
	.notification-icon i{
		font-size: 22px;
    	color: var(--gray-2);
	}
}
</style>
