var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"attendance-list-item",class:{ inactive_item: (_vm.studentData.status!=null && _vm.studentData.status.toLowerCase()=='inactive') },on:{"click":_vm.toggleViewEditUser}},[_c('div',{staticClass:"user-avatar"},[(_vm.studentData.profilePicture)?_c('img',{attrs:{"src":_vm.studentData.profilePicture}}):_c('span',[_vm._v(_vm._s(_vm.studentData.firstName[0]))])]),_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"text-black"},[_vm._v(_vm._s(_vm.studentData.firstName)+" "+_vm._s(_vm.studentData.lastName)+" "),(_vm.studentData.status!=null && _vm.studentData.status.toLowerCase()=='inactive')?_c('span',{staticClass:"text-danger"},[_vm._v(" (Inactive) ")]):_vm._e()]),_c('span',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.studentData.email)+" "+_vm._s(_vm.studentData.phone ? " | " + _vm.studentData.phone : ""))])]),_c('div',{staticClass:"right-menu ms-auto"},[_c('div',{staticClass:"content-cta demo gap-2 d-flex"},[((_vm.type != _vm.AttendanceStatus.ONLINE && _vm.type != _vm.AttendanceStatus.UNPAID && _vm.type == _vm.AttendanceStatus.ABSENT ))?_c('div',{staticClass:"dropdown"},[_c('button',{staticClass:"dropbtn border-btn preview",attrs:{"type":"button"},on:{"click":[function($event){$event.stopPropagation();},_vm.toggleCommentModel]}},[_c('i',{staticClass:"far fa-comment"}),(_vm.studentData.Reason)?_c('i',{staticClass:"fas fa-circle comment-notify-batch"}):_vm._e()]),_c('div',{staticClass:"dropdown-content"},[(_vm.studentData.Reason)?_c('p',{staticClass:"p-2"},[_vm._v(" "+_vm._s(_vm.studentData.Reason)+" ")]):_vm._e()])]):_vm._e(),((_vm.type != _vm.AttendanceStatus.ONLINE && _vm.type != _vm.AttendanceStatus.UNPAID && _vm.showButtons))?_c('button',{class:[
					'border-btn preview',
					{
						danger: _vm.type == _vm.AttendanceStatus.PRESENT,
						success: _vm.type == _vm.AttendanceStatus.ABSENT,
					},
				],attrs:{"disabled":_vm.loading,"type":"button"},on:{"click":_vm.markAttendance}},[(_vm.loading)?_c('b-spinner',{attrs:{"small":""}}):(!_vm.loading && _vm.type == _vm.AttendanceStatus.ABSENT)?_c('i',{staticClass:"far fa-user-plus"}):(!_vm.loading && _vm.type == _vm.AttendanceStatus.PRESENT)?_c('i',{staticClass:"far fa-user-minus"}):_vm._e(),_vm._v(" "+_vm._s(_vm.buttonText)+" ")],1):_vm._e()])]),_c('ViewEditUserModal',{attrs:{"user":_vm.userToViewEdit.user,"openTab":_vm.userToViewEdit.openTab,"showModal":_vm.showUserDetailModal,"viewOnly":""},on:{"updateModalState":_vm.toggleViewEditUser}}),_c('b-modal',{attrs:{"id":"absent-comment-modal","visible":_vm.isShowCommentModel,"hide-header":"","centered":"","size":"md","body-class":"modal-body","hide-footer":"","modal-class":"custom-modal"}},[_c('div',{staticClass:"body-container"},[_c('h4',{staticClass:"add-content-title"},[_vm._v("Absent Comment")]),_c('br'),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.textComment),expression:"textComment"}],staticClass:"form-control",attrs:{"placeholder":"Enter Comment"},domProps:{"value":(_vm.textComment)},on:{"input":function($event){if($event.target.composing)return;_vm.textComment=$event.target.value}}})]),_c('div',[_c('center',[_c('button',{staticClass:"step-btn",attrs:{"disabled":_vm.loading,"type":"button"},on:{"click":_vm.saveComment}},[_vm._v("Save")])])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }